import axios from "axios";

export class SegmentoEscolar {
  static async obtemTodos() {
    let anoAtual = sessionStorage.getItem("anoSelecionado");

    return axios.get("/segmento/ano/" + anoAtual);
  }
  static async obtemTodosPG(n) {
    let anoAtual = sessionStorage.getItem("anoSelecionado");

    return axios.get("/segmento/" + anoAtual + "/pg?page=" + n);
  }
  static async obtemUm(id) {
    return axios.get("/segmento/" + id);
  }
  static async cadastrar(payload) {
    return axios.post("/segmento", payload);
  }
  static async alterar(payload) {
    return axios.put("/segmento/" + payload.id, payload);
  }
  static async remover(id) {
    return axios.delete("/segmento/" + id);
  }

  static async listarSegmentosPorSerie(id) {
    return axios.get("/segmento/" + id + "/series");
  }
  static async tipoSegmento(tipo) {
    return axios.get("segmento/tipo/" + tipo);
  }
}
